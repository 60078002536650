<template>
  <loader v-if="loading" />

  <event-details v-else class="route-content" :event="onlineClass" />
</template>

<script>
import { getEventById } from "@/models/events";
import Loader from "./Loader.vue";
import EventDetails from "./EventDetails.vue";
import PermissionsMixin from "./mixins/permissions.mixin";

export default {
  name: "ViewEvent",

  components: { Loader, EventDetails },

  mixins: [PermissionsMixin],

  data: () => ({ class: null, loading: true }),

  mounted() {
    const { classId } = this.$route.params;
    if (classId) this.fetchEvent(classId);
  },

  methods: {
    async fetchEvent(classId) {
      this.startLoading("Fetching Class ... ");
      this.onlineClass = await getEventById(classId);
      this.stopLoading();
    }
  }
};
</script>
